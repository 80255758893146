.main-footer {
  background: #DDE6ED;
  padding: 15px;
  color: #444;
//   border-top: 1px solid #68B2E3;
  position: fixed;
  width: 100%;
  bottom: 0;
  border-top: 1px solid rgba(104,178,227,0.5);
}
.footLine {
  background: #DDE6ED;
  height: 54px;
  color: #444;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: -1;
}
