.manualUpdateModal[role="dialog"] {
  min-width: 620px !important;
  .ManualUpdate {
    width: 100%;
    p {
      padding: 0;
      margin: 0;
    }
    .input {
      margin: 0;
      margin-left: 45px;
      margin-bottom: 10px;
      //   width: 100%;
    }

    .DateWrapper {
      margin-left: 45px;
      width: 568px;
      height: 50px;
      position: relative;
      margin-top: 5px;
      .IconHolder {
        position: absolute;
        width: 48px;
        height: 50px;
        top: 0px;
        left: -45px;
        background: #f7f7f7;
        border: 1px solid #b8b8b7;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        .DateTimeIcon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .rDate {
        width: 520px;
        padding-left: 15px;
        height: 50px;
        &:focus {
          border-color: #68b2e3;
          box-shadow: 0 1px 1px #68b2e3 inset, 0 0 10px #68b2e3;
          outline: 0 none;
        }
      }
    }
    .messageBox {
      float: left;
      margin-top: 25px;
      #message {
        font-size: 15px;
        color: #be1e2d;
      }
    }
    .saveButton {
      height: 40px;
      width: 120px;
      font-size: 16px;
      float: right;
      margin-top: 15px;
      margin-right: 15px;
      margin-bottom: 15px;
      span {
        &:nth-child(2) {
          position: absolute;
          top: 50%;
          left: 50%;
          margin : 0;
          svg{
            color: #68b2e3;
          }
        }
      }
    }
  }
}
