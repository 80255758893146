@import "../../../../CoreStyle/CoreStyle";
.YesNoModal{


  #YesNoMessage {
    margin: 0 !important;
    padding: 0 !important;
  }
  .YesButton {
    float: left !important;
    background: $yesGreen !important;
    width: 90px !important;
    font-size: 18px !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    font-family: $boldFont;
    height: 35px;
  }
  .NoButton {
    float: right;
    background: $noRed;
    width: 90px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    height: 35px;
    // margin-bottom: 10px;
  }
  #butonSpin{
    width: 12px !important;
    height: 12px !important;
  }
}
.YesNoModal[role=dialog]
{
    min-width: 400px;
    min-height: 150px;
}