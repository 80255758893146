@import "../../CoreStyle/CoreStyle";
.headLine {
  width: 100%;
  height: 60px;
  background: white;
  z-index: -1;
  padding: 0 !important;
}
.navBarItem.deActive {
  cursor: default !important;
  // background: $uiDisabled !important;
  color: $uiEnabled !important; //uiDisabled hard to read.
  &:hover {
    background: white !important;
  }
}
.UserDrawer-Pop {
  width: 150px;
}
.navBarItem {
  user-select: none;
  cursor: pointer;
}
.navBarItem.Active {
  cursor: default;
  &:hover {
    background: none;
  }
}
.navBarItem.Selected {
  cursor: default;
  &:hover {
    background: #eaf4fb !important;
  }
}
.userSection {
  user-select: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  #userName {
    margin-right: 5px;
    font-size: 14px;
  }
  .userButton {
    width: 25px;
    height: 25px;
  }
}
