@import "../../CoreStyle/CoreStyle";
@mixin CellSize {
  .ptbody {
    min-height: 200px;
  }
  .ptbody > tr td:nth-child(1),
  .ptbody > tr td:nth-child(2),
  .pthead > tr th:nth-child(1),
  .pthead > tr th:nth-child(2) {
    width: 8% !important;
  }
  .ptbody > tr td:nth-child(3),
  .ptbody > tr td:nth-child(4),
  .pthead > tr th:nth-child(3),
  .pthead > tr th:nth-child(4) {
    width: 3% !important;
  }

  .pthead > tr th:nth-child(7),
  .ptbody > tr td:nth-child(7) {
    width: 3% !important;
  }
  .pthead > tr th:nth-child(8),
  .ptbody > tr td:nth-child(8) {
    width: 4%;
  }
  .pthead > tr th:nth-child(9),
  .ptbody > tr td:nth-child(9) {
    width: 7% !important;
  }

  .ptbody > tr td:nth-child(5),
  .pthead > tr th:nth-child(5),
  .ptbody > tr td:nth-child(6),
  .pthead > tr th:nth-child(6),
  .ptbody > tr td:nth-child(10),
  .pthead > tr th:nth-child(10) {
    width: 6% !important;
  }
  .ptbody > tr td:nth-child(11),
  .pthead > tr th:nth-child(11)
  {
    width: 1.5% !important;
  }
}

.voyageTableContainer {
  position: absolute;
  top: 60px;
  // background: white;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: calc(100% - 60px - 55px);
  max-width: 1500px;
  overflow: hidden; //inherit

  .voyageTableHeader {
    width: 100%;
    // margin-top : 50px;
    margin-top: 15px;
    height: 50px;
    margin-bottom: 15px;
    position: relative;
    z-index: 6;
    /*border-bottom: 1px solid #002B45;*/

    .searchBtn {
      vertical-align: top !important;
      width: 200px !important;
      height: 50px !important;
      margin-left: 15px !important;
      float: right !important;
    }
    .DateInputContainer {
      margin-left: 10px;
    }
    .datePickerContainer {
      width: 500px;
      display: inline-block;
    }
    .DayPicker {
      left: 0 !important;
      width: 744px;
    }
    .css-79elbk.em3vnrq0 {
      /*ComboBox , SelectBox*/
      vertical-align: top;
      display: inline-block;
      width: 150px !important;
      margin-left: 15px;
      height: 50px !important;
    }
    .datepicker_container {
      width: 250px !important;
      background: white;
    }
    .datepicker_container .dateInputs {
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-color: $groupBlue;
      //   text-decoration-style: wavy;
      height: 50px !important;
    }
    .dateInputs__label {
      position: absolute;
      font-size: 16px !important;
      margin-top: 5px !important;
      /* vertical-align: middle;*/
    }
    .departurePort,
    .arrivalPort {
      font-size: 16px !important;
    }
  }

  .voyageTableContent {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    height: calc(100% - 125px);
    .PerfectTable {
      position: relative;
      height: calc(100% - 0px);
      min-width: 1000px;
      .ptbody {
        box-sizing: border-box;
        // padding-right: 10px;
        overflow-y: overlay !important;

        max-height: calc(100% - 50px);
        scrollbar-width: thin;
      }
      .subTable {
        min-width: 1000px;
      }
      @include CellSize();
      tr:hover svg path:nth-child(2) {
        // fill: white;
      }
    }
  }
}

.voyageTableHeader {
  .css-79elbk.em3vnrq0 {
    width: 175px !important;
  }
}
.title {
  position: absolute;
  top: 0;
  left: 0;
}
.posCenter {
  // text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (max-width: 1350px) {
  .voyageTableContainer {
    .voyageTableHeader {
      .css-79elbk.em3vnrq0 {
        width: 175px !important;
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
  .voyageTableContainer {
    .voyageTableHeader {
      .css-79elbk.em3vnrq0 {
        width: 150px !important;
      }
      .departurePort,
      .arrivalPort {
        font-size: 14px !important;
      }
    }
  }
}
@media only screen and (max-width: 1235px) {
  .voyageTableContainer {
    .voyageTableHeader {
      .css-79elbk.em3vnrq0 {
        width: 125px !important;
      }
      .departurePort,
      .arrivalPort {
        font-size: 13px !important;
      }
    }
  }
}
@media only screen and (max-width: 1230px) {
  .voyageTableContainer {
    .voyageTableHeader {
      .dotBtn.searchBtn {
        width: 150px !important;
      }
      .departurePort,
      .arrivalPort {
        font-size: 12px !important;
      }
    }
  }
}
@media only screen and (max-width: 1120px) {
  .voyageTableContainer {
    .voyageTableHeader {
      .datePickerContainer {
        width: 400px;
      }
      .datepicker_container {
        width: 200px !important;
      }
      .departurePort,
      .arrivalPort {
        font-size: 11px !important;
      }
    }
  }
}
