.CargoTableContainer {
  .PerfectTable {
    line-height: 14px;
    .pthead > tr th:nth-child(3),
    .ptbody > tr td:nth-child(3) {
      width: 12% !important;
    }
    .pthead > tr th:nth-child(4),
    .ptbody > tr td:nth-child(4) {
      width: 12% !important;
    }
    .pthead > tr th:nth-child(1),
    .ptbody > tr td:nth-child(1) {
      width: 13% !important;
    }
    // .ptbody > tr td:nth-child(1),
    .ptbody > tr td:nth-child(3),
    .ptbody > tr td:nth-child(4) {
      #cellText {
        float: right;
        margin-right: 10px;
      }
    }
  }
}
