.TagBox-Container {
  height: 30px;
  padding: 5px;
  margin-left: 15px;
  background-color: #68b2e3;
  #TagBox-FilterText {
    vertical-align: middle;

    font-size: 16px;
    margin-left: 15px;
    margin-right: 20px;
    color: #f1f7fc;
  }
  #TagBox-CloseButton {
    vertical-align: middle;
    color: white;
    width: 15px;
    height: 15px;
    margin-top: 2px;
    margin-right: 5px;
    float: right;
    cursor: pointer;
  }
}
