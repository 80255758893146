.Page-Container {
    position: absolute;
    overflow: hidden;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(86vw - 15px);
    height: calc(100vh - 40px - 100px);
    max-width: 1500px;
}
.AdminPanel-Container {
    position: relative;
    width: 100%;
    margin-top: 20px;
    height: calc(100% - 55px - 60px - 1px - 20px /*border*/);
    .Submenu-Container {
        position: absolute;
        display: inline-block;
        width: 15%;
        height: 95%;
        background-color: #002b45;
        .Menu-Container {
            padding: 10px;
            padding-right: 1px;
            padding-left: 1px;
            .ACTIVE_MENU {
                background-color: #345370 !important;
                color: white !important;
            }
            #Menu-Header {
                padding-left: 5px;
                display: block;
                font-size: 20px;
                color: white;
                // border-bottom: 1px solid white;
                padding-bottom: 5px;
                margin-bottom: 5px;
                user-select: none;
            }
            #Menu-Text {
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 5px;
                margin-bottom: 5px;
                display: block;
                cursor: pointer;
                user-select: none;
                border: 1px solid rgba(0, 0, 0, 0);
                &:hover {
                    border: 1px solid white;
                    // color: #002b45;
                }
                color: white;
            }
        }
    }
    .Content-Container {
        position: absolute;
        left: calc(15% + 10px);
        display: inline-block;
        width: 85%;
        height: 95%;
        // background-color: red;
    }
}
