$inputHeight: 40px;
.signModal[role="dialog"] {
  min-width: 740px !important;
  max-height: 600px;
  ::-webkit-scrollbar-track {
    background: #e4e5e4 !important;
  }
  .UserSelection {
    width: 100%;
    height: 40px;
    // margin-bottom: 5px;
    > div{
      width: 200px;
      height: 30px;
      display: inline-block;
      margin-left: 5px;
      padding: 0;
    }
    span{
      height: 30px;
      svg{
      }
    }
    .userBox {
      width: 200px;
      height: 30px;
      padding: 0;
      padding-left: 10px;
      padding-top: 5px;
    }
    span#SelectSignUser {
      margin: 0;
      padding: 0;
      display: inline-block;
      font-size: 20px;
    }
  }
  #XMLWait {
    margin: 0;
    padding: 0;
  }
  .xmlViewer {
    width: 700px;
    max-height: 400px;
    overflow-y: auto;
    background: #f5f6f7;
    // padding: 10px;
    border: solid rgba(0, 0, 0, 0.25) 1px;
    &:hover {
      border: solid rgba(0, 0, 0, 0.5) 1px;
    }
  }
  .signActions {
    position: relative;
    margin-top: 20px !important;
    .input {
      width: 200px !important;
      height: $inputHeight !important;
      margin-left: 45px !important;
      margin-top: 0px !important;
      .imgCont {
        height: $inputHeight;
      }
      .pinCodeInput {
        width: 200px;
        height: $inputHeight !important;
        span {
          height: $inputHeight !important;
        }
        input {
          height: $inputHeight !important;
        }
      }
    }

    .pinSignButton {
      width: 100px;
      height: $inputHeight !important;
      position: absolute;
      right: 0;
      top: 0;
    }
    .pinSignButton .SIGNED {
      background: #5aa63b;
    }
  }
}
