@import "../../../CoreStyle/CoreStyle.scss";
.declarationTableContainer {
  .pRow {
    cursor: default !important;
  }
  .ptbody {
    min-height: 200px;
  }
  .pthead > tr th:nth-child(1),
  .ptbody > tr td:nth-child(1) {
    width: 13% !important;
  }

  .pthead > tr th:nth-child(2),
  .ptbody > tr td:nth-child(2) {
    width: 13% !important;
  }

  .pthead > tr th:nth-child(3),
  .ptbody > tr td:nth-child(3) {
    width: 15% !important;
  }

  .pthead > tr th:nth-child(4),
  .ptbody > tr td:nth-child(4) {
    width: 13% !important;
  }
  .pthead > tr th:nth-child(6),
  .ptbody > tr td:nth-child(6),
  .pthead > tr th:nth-child(7),
  .ptbody > tr td:nth-child(7),
  .pthead > tr th:nth-child(8),
  .ptbody > tr td:nth-child(8),
  .pthead > tr th:nth-child(9),
  .ptbody > tr td:nth-child(9),
  .pthead > tr th:nth-child(10),
  .ptbody > tr td:nth-child(10) {
    width: 6% !important;
  }
  .DeclarationTableHeader {
    margin-bottom: 5px;
    #PDFButton {
      margin-left: 10px;
    }
    #detailsButton {
      height: 30px;
      width: 90px;
      font-size: 12px;
    }
  }
}
.reactIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.DownloadButton {
  color: $yesGreen;
}
.ManualButton {
  width: 25px;
  height: 25px;
}
.PDFButton {
  // width: 28px;
  // height: 28px;
}
.DOWNLOADING {
  width: 24px;
  height: 24px;
  cursor: progress;
  svg {
    width: 24px;
    height: 24px;
    // color: $yesGreen;
  }
}
