@import "../../../CoreStyle/CoreStyle.scss";
.voyageRouteTableContainer {
  .InfoTable {
    thead.pthead {
      display: none !important;
    }
    .pRow {
      &:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.25);
      }
      &:hover {
        background: none !important;
        color: black !important;
        cursor: default !important;
      }
      td#pTd {
        &:nth-child(6) {
          span#cellText {
            float: right;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
