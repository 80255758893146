@import "../../CoreStyle/CoreStyle.scss";
.DeclarationContainer {
  width: 100%;
  height: calc(100% - 60px - 55px - 15px);
  margin-top: 15px;
  #detailsButton{
    width: 70px;
    height: 25px;
    font-size: 14px;
    padding: 0;
    margin: 0;
    margin-top: 1px;
    margin-left: 5px;
  }
  .voyageRouteTableContainer {
    width: 100%;
    margin-bottom: 15px;
  }
  .declarationTableContainer {
    margin-top: 35px;
    width: 100%;
    td#pTd{
      line-height: 14px;
    }
  }
}

