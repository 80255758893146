div.spinDiv {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background: rgba(238, 240, 241, 0.692);
  opacity: 0.3;
  z-index: 1;
}
.centeredSpinner {
}
.centeredSpinner svg {
  width: 45px !important;
  height: 45px !important; 
}
div.spinContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background: none;
  opacity: 0.9;
  z-index: 9999;
}
.dfdsLogo {
  position: absolute;
  left: 50%;
  top: calc(50% - 50px - 50px);
  transform: translate(-50%, -50%);
}
