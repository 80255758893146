$NavBarHeight: 60px;
$FooterHeight: 50px;
$PageMargin: 15px;
$ColumnHeader: 40px;
.DetailsContainer {
  width: 100%;
  height: calc(100vh - 60px - 55px - 15px);
  margin-top: 15px;
  line-height: 20px;
  .pageHeader {
    margin-bottom: 0px;
  }
  .Declaration-Number-Filter-Container {
    height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    > div {
      display: inline-block;
      height: 30px;
    }
    .Declaration-Number-Filter-Input {
      display: inline-block;
      width: 200px;
      font-size: 14px;
      height: 30px;
    }
    .Declaration-Number-Filter-Button {
      display: inline-block;
      height: 30px;
      margin-left: 5px;
    }
  }
  .bookingTableContainer {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    overflow: hidden;
    // height: 200px;
    max-height: calc((100vh - 60px - 55px - 10px - 30px) * 45 / 100);
    .BookingTable {
      .ptbody {
        max-height: calc((100vh - 60px - 55px - 10px - 30px) * 45 / 100 - 40px - 30px) !important;
        overflow-y: auto;
      }
    }

    h4#bTableHeader {
      margin: 0;
      padding: 0;
    }
  }
  .CargoTableContainer {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    max-height: calc((100vh - 60px - 55px - 15px) * 35 / 100);
    overflow: hidden;
    .CargoTable {
      .ptbody {
        max-height: calc((100vh - 60px - 55px - 15px) * 35 / 100 - 40px - 30px) !important;
        overflow-y: auto;
      }
    }
  }
}
