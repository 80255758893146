.HistoryModal {
  overflow: hidden;
  .PerfectTable {
    .ptbody {
      max-height: 360px;
      overflow-y: overlay;
    }
    .pthead > tr th:nth-child(1),
    .ptbody > tr td:nth-child(1) {
      width: 15% !important;
    }
    .pthead > tr th:nth-child(2),
    .ptbody > tr td:nth-child(2) {
      width: 15% !important;
    }
    .pthead > tr th:nth-child(3),
    .ptbody > tr td:nth-child(3) {
      width: 10% !important;
    }
    .pthead > tr th:nth-child(4),
    .ptbody > tr td:nth-child(4) {
      width: 5% !important;
    }
    .pthead > tr th:nth-child(5),
    .ptbody > tr td:nth-child(5) {
      width: 45% !important;

      span#cellText {
        line-height: 14px;
      }
    }
    td#pTd:nth-child(5) {
      padding-right: 20px !important;
    }
    .Collapsable {
      cursor: pointer;
      .Collapsable-Arrow {
        margin-left: 5px;
        width: 10px;
        height: 10px;
      }
      .Arrow-Up {
        transform: rotateZ(180deg);
      }
    }
  }
  .CopyDesc-Container {
    margin-left: 5px;
  }
}
