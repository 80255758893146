.ErrorLogModal[role="dialog"] {
  max-width: 900px;
  max-height: 600px;

  .CopyErrorMessages-Container {
    margin-left: 5px;
    width: 20px;
    height: 24px;
    #CopyButton-CopyIcon {
      width: 20px;
      height: 20px;
    }
  }
  #Errorlog-Container-Title {
    font-size: 16px;
    border-bottom: 1px solid #002b45ab;
  }
  .Errorlog-Container {
    max-height: 300px;
    width: 800px;
    overflow-y: auto;
    #ErrorLog {
      display: block;
      word-wrap: break-word;
      font-size: 16px;
      line-height: 20px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
