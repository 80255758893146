.HistoryModal[role="dialog"]{
    min-width: 1280px;
    .PerfectTable{
        .pRow{
            cursor: default !important;
        }
        #pTd{
            line-height: 20px;
            white-space: pre-wrap;
        }
    }
}