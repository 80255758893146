.JustDialog-Container {
  position: absolute;
  z-index: 999;
  width: 230px;
  // max-width: 250px;
  background-color: white;
  outline: 1px solid rgba(0, 0, 0, 0.25);
  .JustDialog-Text-Container {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    cursor: pointer;
    &:hover {
      background-color: #345370;
      color: white;
      fill: white;
    }
    color: #002b45;
  }
  .JustDialog-Option-Disabled {
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    background-color: #eef0f1;
    cursor: not-allowed;
  }
  #JustDialog-Text {
    font-size: 12px;
  }
  #JustDialog-Icon {
    margin-right: 10px;
    margin-left: 5px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
}
