$ModalHeight: 600px;
$ModalWitdh: 700px;
.Pipeline-Modal-Container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin-left: calc(-50vw + 50%);
  background: #4d4e4cee;
  z-index: 999999;
  .Pipeline-Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $ModalWitdh;
    max-height: $ModalHeight;
    background: #e8f4fb;
    box-shadow: 0px 0px 1px 1px white;
    padding-left: 5px;
    padding-right: 5px;
    .Pipeline-Modal-Header {
      position: relative;
      width: 100%;
      height: 50px;
      // background: red;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      .Pipeline-Modal-CloseButton {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        cursor: pointer;
      }
      #Pipeline-Modal-Title {
        font-size: 20px;
        line-height: 50px;
        margin-left: 10px;
      }
    }
    .Pipeline-Modal-Content {
      max-height: calc(#{$ModalHeight} - 100px);
      width: 100%;
      .Pipeline-Warning-Container {
        position: relative;
        margin-top: 5px;
        background: #002b45;
        .Pipeline-Warning-Icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 5px;
          width: 24px;
          height: 24px;
          color: #ffc108;
        }
        #Pipeline-Warning-Message {
          padding: 5px;
          margin-left: 35px;
          color: #ffc108;
        }
      }
      .Pipeline-Container {
        // margin-right: 15px;
        .Pipeline {
          position: relative;
          width: 100%;
          border-bottom: 1px solid #002b45aa;
          margin-bottom: 10px;

          .Pipeline-StatusIcon {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-bottom: 3px;
            svg {
              width: 20px;
              height: 20px;
              margin-bottom: 3px;
            }
          }
          #Pipeline-PipeText {
            font-size: 16px;
            line-height: 16px;
            white-space: normal;
            margin-left: 8px;
            // padding-left: 10px;
          }
        }
        .Pipeline-Terminal {
          width: 100%;
          height: 350px;
          background: #002b45;
          padding: 10px;
          overflow-y: overlay;
          #Pipeline-Terminal-Data {
            display: block;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 18x;
            color: white;
          }
        }
      }

      // background: red;
    }
    .DONE {
      background: #5aa63b !important;
    }
    .ERROR {
      background: #be1e2d !important;
    }
    .Status-WARNING {
      // color: #be1e2d !important ;
    }
    .Status-INFO {
      // color: #ffc108 !important ;
    }
    .Pipeline-Modal-Footer {
      margin-top: 10px;
      min-height: 20px;
      max-height: 50px;
      width: 100%;
      // background: green;
      .Pipeline-Modal-ResponseButton {
        float: right;
        width: 150px;
        height: 40px;
        margin-bottom: 5px;
      }
    }
  }
}
