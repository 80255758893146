.bookingTableContainer {
  .pthead > tr th:nth-child(1),
  .ptbody > tr td:nth-child(1) {
    width: 12%;
  }
  .pthead > tr th:nth-child(2),
  .ptbody > tr td:nth-child(2) {
    width: 10%;
  }
  .pthead > tr th:nth-child(3),
  .ptbody > tr td:nth-child(3) {
    width: 6%;
  }
  .pthead > tr th:nth-child(4),
  .ptbody > tr td:nth-child(4) {
    width: 6%;
  }
  .pthead > tr th:nth-child(5),
  .ptbody > tr td:nth-child(5),
  .pthead > tr th:nth-child(6),
  .ptbody > tr td:nth-child(6) {
    width: 10%;
  }
  .pthead > tr th:nth-child(9),
  .ptbody > tr td:nth-child(9),
  .pthead > tr th:nth-child(10),
  .ptbody > tr td:nth-child(10) {
    width: 7% !important;
  }
  .ptbody > tr td:nth-child(8) {
    #cellText {
      margin-right: 10px;
    }
  }
  .ptbody > tr td:nth-child(9) {
    #cellText {
      float: right;
      margin-right: 5px;
    }
  }
  .ptbody > tr td:nth-child(10) {
    #cellText {
      float: right;
      margin-right: 5px;
    }
  }
  #pTd {
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden;
  }
  span#cellText {
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden;
  }
  .pRow {
    height: 40px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
