@import "../../../CoreStyle/CoreStyle.scss";
.spinBtn {
  position: relative;
  background-color: $primaryOrange !important;
  color: white;
  width: 160px;
  height: 40px;
  border: none;
  transition: 0.5s;
  border-radius: 2px;
  font-family: $boldFont;
  font-size: 16px;
  text-align: center;
  &:hover {
    background-color: $primaryHover;
  }
  .rotateIt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: rotate 2s linear infinite;
  }
  button.disabled {
    background-color: $primaryHover !important;
    cursor: progress !important;
  }
  #spinButtonText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.spinBtn.disabled {
  background: #b8b8b7 !important;
  cursor: progress;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
