@import '../../CoreStyle/CoreStyle';
.Background{
    z-index: -2;
    width: 100vw;
    position: absolute;
    left: calc(1260px / 2);
    transform: translateX(-50%);
    height: calc(100% - 115px);
    background-image: url("../../assets/images/login.jpg");
    background-size: cover;
    .Background-cover{
        z-index: -1;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: .3;
    }
}
.loginForm{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: white;
    width: 600px;
    // height: 300px;
    border : 1px solid $groupMedium ;

    .loginFormHeader{
        position: absolute;
        width: 100%;
        height: 50px;
        background: #eef0f1;
        text-align: center;
        line-height: 50px;
        vertical-align: middle;
        user-select: none;
        border-bottom: 1px solid $groupMedium;
        font-family: $boldFont;
    }
    .inputArea{
        position: absolute;
        top: 50px;
        width: 100%;
        height: 145px;
    }
    .loginFormFooter{
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #eef0f1;
        height: 60px;
        border-top: 1px solid $groupMedium;
        .signInBtn{
            float: right;
            margin-top: 10px;
            margin-right: 22px;
        }
        #forgetPass{
            font-size: 14px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
            color: $groupBlue;
            cursor: pointer;
            text-decoration: underline;
            text-underline-position: under;

        }
    }
}




